// import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
import BaseSelect from '@/components/common/base-select/base-select'
import { getDictLists } from '@/filters/fromDict'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
// 查询form
export const formConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '合同名称',
      prop: 'contractName',
      rules: [
        { required: false, message: '请输入合同名称', trigger: 'change' }
      ],
      attrs: {
        placeholder: '请输入',
        maxlength: '50',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 }
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      rules: [
        { required: true, message: '请输入实际合同名称', trigger: 'change' }
      ],
      attrs: {
        placeholder: '请输入',
        maxlength: '100'
      },
      on: {
        input (data) {
          content.finContractNameInput(data)
        }
      },
      cols
    },
    {
      label: '中登查询',
      prop: 'zdMode',
      rules: [
        { required: true, message: '请选择中登查询', trigger: 'change' }
      ],
      attrs: {
        placeholder: '请选择',
        options: getDictLists('CHINA_REGISTER_QUERY'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      cols
    },
    {
      label: '付款方式',
      prop: 'paymentType',
      tag: BaseSelect,
      rules: [
        { required: true, message: '请选择付款方式', trigger: 'change' }
      ],
      attrs: {
        placeholder: '请选择',
        options: getDictLists('PAYMENT_TYPE'),
        selectedField: ['dictId', 'dictName']
      },
      cols
    },
    {
      label: '所属区域',
      prop: 'area',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: false, message: '请输入所属区域', trigger: 'change' }
      ],
      cols
    },
    {
      label: '金地控股比例(%)',
      prop: 'shareholdingRatio',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      rules: [
        { required: true, message: '请输入金地控股比例', trigger: 'change' }
      ],
      tag: AmountInput,
      cols
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      attrs: {
        placeholder: '请输入',
        type: '1000Y'
      },
      rules: [
        { required: true, message: '请输入转让价值', trigger: 'change' }
      ],
      on: {
        blur (data) {
          content.changePledgeValue(data)
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '融资金额(元)',
      prop: 'relLoanAmount',
      attrs: {
        placeholder: '请输入',
        type: '1000Y'
      },
      rules: [
        { required: true, message: '请输入融资金额', trigger: 'change' }
      ],
      on: {
        blur () {
          content.changeRelLoanAmount()
          content.getDynamicPaymentInfo()
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      attrs: {
        placeholder: '请输入',
        type: 'BL',
        disabled: true
      },
      rules: [
        { required: false, message: '请输入质押率', trigger: 'change' }
      ],
      tag: AmountInput,
      cols
    },
    {
      label: '进度款比例(%)',
      prop: 'progressPaymentPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      rules: [
        { required: true, message: '请输入进度款比例', trigger: 'change' }
      ],
      on: {
        blur () {
          content.getDynamicPaymentInfo()
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '竣工款比例(%)',
      prop: 'completionPaymentPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      rules: [
        { required: false, message: '请输入竣工款比例', trigger: 'change' }
      ],
      on: {
        blur () {
          content.getDynamicPaymentInfo()
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '结算款比例(%)',
      prop: 'settlementPaymentPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      rules: [
        { required: true, message: '请输入结算款比例', trigger: 'change' }
      ],
      on: {
        blur () {
          content.getDynamicPaymentInfo()
        }
      },
      tag: AmountInput,
      cols
    },
    {
      label: '质保金比例(%)',
      prop: 'qualityGuaranteePercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      on: {
        blur () {
          content.getDynamicPaymentInfo()
        }
      },
      rules: [
        { required: false, message: '请输入质保金比例', trigger: 'change' }
      ],
      tag: AmountInput,
      cols
    }
  ]
}
export const cooperativeConfig = (content) => {
  return [
    {
      label: '合作方式和现金情况',
      prop: 'cooperationCashFlow',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 500
      },
      span: 24
    }]
}
// 项目资金计划表格
export const planConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '日期',
      prop: 'time'
    },
    {
      label: '建设进度',
      prop: 'buildProcess',
      align: 'left'
    },
    {
      label: '预计收回款项',
      prop: 'expectedDueAmount'
    },
    {
      label: '预计投入款项',
      prop: 'expectedInvestAmount'
    },
    {
      label: '收入/投入类型',
      prop: 'dueInvestType',
      align: 'left'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 付款情况
export const paymentConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '累计完成工程产值(元)',
      prop: 'outputTotalAmount',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '累计应付金额(元)',
      prop: 'noPayTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '应付金额进度比例(%)',
      prop: 'noPayTotalPercent',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收竣备款(元)',
      prop: 'progressTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收结算款(元)',
      prop: 'settlementTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '当前应收质保金(元)',
      prop: 'retentionTotalAmount',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '合同未来应收工程款(元)',
      prop: 'futureProjectPaymentReceivable',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    },
    {
      label: '合同未来应收结算款(元)',
      prop: 'futureProjectSettlementReceivable',
      attrs: {
        placeholder: '请输入',
        type: 'Amount',
        disabled: true
      },
      type: 'Amount',
      cols
    }
  ]
}
// 评价
export const evaluateConfig = (content, index) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '评价人类型',
      prop: 'evaluatorType',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('EVALUATOR_TYPE'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      cols
    },
    {
      label: '评价人姓名',
      prop: 'evaluatorName',
      attrs: {
        placeholder: '请输入',
        maxlength: '100'
      },
      cols
    }

  ]
}
export const contractConfig = (content) => {
  return [
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入合同名称',
        maxlength: '50'
      },
      rules: [
        { required: false, message: '请输入合同名称', trigger: 'change' }
      ]

    },
    {
      label: '合同编号',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入合同编号',
        maxlength: '50'
      },
      rules: [
        { required: false, message: '请输入合同编号', trigger: 'change' }
      ]

    }
  ]
}
export const contractTableConfig = (content) => {
  return [
    {
      type: 'selection',
      'min-width': '100px'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      align: 'left'
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '项目公司名称',
      prop: 'projectDebtor',
      minWidth: '100px',
      align: 'left'
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      align: 'right',
      minWidth: '100px',
      formatter: (row) => {
        return row.finContractAmount
          ? (row.finContractAmount * 1).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      align: 'right',
      minWidth: '100px',
      formatter: (row) => {
        return row.pledgeValue
          ? (row.pledgeValue * 1).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    }
  ]
}
export const tableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '30px'
    },
    {
      label: '合同名称',
      prop: 'contractName',
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      'show-overflow-tooltip': true
    },
    {
      label: '实际合同名称',
      prop: 'finContractName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '项目公司名称',
      prop: 'projectDebtor',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同对价(元)',
      prop: 'finContractAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.finContractAmount
          ? (row.finContractAmount * 1).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '转让价值(元)',
      prop: 'pledgeValue',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.pledgeValue
          ? (row.pledgeValue * 1).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '对应融资额(元)',
      prop: 'relLoanAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: (row) => {
        return row.relLoanAmount
          ? (row.relLoanAmount * 1).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
          : '0'
      }
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      'show-overflow-tooltip': true
    },

    {
      label: '操作',
      prop: 'action'
    }
  ]
}
